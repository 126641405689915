import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import './styles.scss';

export const query = graphql`
    query ArticleFooterTextQuery {
        site {
            siteMetadata {
                links {
                    twitter
                }
            }
        }
    }
`;

export default class ArticleFooterText extends React.Component {
    render () {
        return (
            <StaticQuery query={query} render={(props) => (
                <>
                    <p><strong>Rob Farr</strong> &mdash; Solutions Engineering Team Lead at <a href='https://www.netcraft.com/' title='Netcraft' target='_blank' rel='noopener noreferrer'>Netcraft</a> &amp; Computer Science graduate from the <a href='https://www.bath.ac.uk/' title='University of Bath' target='_blank' rel='noopener noreferrer'>University of Bath</a>.</p>
                    <p>You can find out more <Link to='/about'>about me</Link>, or send me a <a href={props.site.siteMetadata.links.twitter} title='Twitter' target='_blank' rel='noopener noreferrer'>tweet</a>.</p>
                </>
            )} />
        );
    }
}
